import actions from './actions'
import mutations from './mutations'

const state = () => {
  return {
    notificacaoAutuacao: [],
    notificacaoPenalidade: [],
    nic: []
  }
}

export default {
  state,
  actions,
  mutations,
  namespaced: true
}
