const setInfracoes = (state, data) => {
  state.placa = data.placa
  state.valorTotalMultas = data.valorTotalMultas

  data.infracoesPendentes.forEach((infracao) => {
    infracao.isPending = true
    infracao.status = 'Pendente'
  })

  data.infracoesComBaixa.forEach((infracao) => {
    infracao.isPending = false
    infracao.status = 'Fechada'
  })

  const infracoes = Array.prototype.concat(data.infracoesPendentes, data.infracoesComBaixa)

  state.infracoes = infracoes.sort(function (a, b) {
    return new Date(b.dataHoraInfracao) - new Date(a.dataHoraInfracao)
  })
}

const setInfracao = (state, data) => {
  state.infracao = data
}

const limpaInfracoes = (state) => {
  state.placa = ''
  state.valorTotalMultas = 0
  state.infracoes = []
}

const adicionarStatusIndicacao = (state, { infracaoId, indicacoes }) => {
  const infracao = state.infracoes.find(i => i.idInfracao === infracaoId)
  indicacoes.data.forEach((indicacao) => {
    infracao.indicacoes.push(indicacao)
  })
}

const setTiposRecurso = (state, { tiposRecurso }) => {
  state.tiposRecurso = tiposRecurso
}

const setNomeCondutor = (state, data) => { state.indicacao.condutor.nomeCompleto = data }
const setCNH = (state, data) => { state.indicacao.condutor.cnh = data }
const setUFCNH = (state, data) => { state.indicacao.condutor.ufCNH = data }
const setTipoCNH = (state, data) => { state.indicacao.condutor.tipoCNH = data }
const setSemAssinatura = (state, data) => { state.indicacao.condutor.semAssinatura = data }

export default {
  setInfracoes,
  limpaInfracoes,
  adicionarStatusIndicacao,
  setInfracao,
  setNomeCondutor,
  setCNH,
  setUFCNH,
  setTipoCNH,
  setSemAssinatura,
  setTiposRecurso
}
