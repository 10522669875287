<template>
  <div>
    <div class="hero-body">
      <div class="container">
        <b-loading :is-full-page="true" :active.sync="loading" :can-cancel="false" />
        <div class="columns is-centered">
          <div class="column is-narrow">
            <form class="box">
              <div class="title is-5">
                CONSULTA DE INFRAÇÃO
              </div>
              <div>
                <div class="block">
                  <b-radio
                    v-model="tipoPesquisa"
                    native-value="placarenavam"
                    type="is-primary"
                    @click.native="clearRadios"
                  >
                    Renavam
                  </b-radio>
                  <b-radio
                    v-model="tipoPesquisa"
                    native-value="placaait"
                    type="is-primary"
                    @click.native="clearRadios"
                  >
                    AIT
                  </b-radio>
                </div>
                <b-field
                  label="Placa"
                  class="mb-0"
                >
                  <b-input
                    v-model="filtro.placa"
                    name="placa"
                    maxlength="7"
                    @change="$v.filtro.placa.$touch()"/>
                </b-field>
                <div
                  class="error mb-1"
                  v-if="
                    !$v.filtro.placa.required &&
                    $v.filtro.placa.$dirty
                  "
                >
                  Campo requerido
                </div>
                <div class="error mb-1" v-if="!$v.filtro.placa.minLength">
                  O campo deve ter no mínimo
                  {{ $v.filtro.placa.$params.minLength.min }} dígitos.
                </div>
                <b-field
                  v-show="tipoPesquisa === 'placarenavam'"
                  label="Número do Renavam"
                  message="* Campo obrigatório"
                  class="mb-3"

                >
                  <b-input
                    v-model="filtro.renavam"
                    name="renavam"
                    maxlength="11"
                    @change="$v.filtro.renavam.$touch()"
                  />
                </b-field>
                <div class="error mb-1" v-if="!$v.filtro.renavam.numeric">
                  Este campo só aceita números.
                </div>
                <div class="error mb-1" v-if="!$v.filtro.renavam.minLength">
                  O campo deve ter no mínimo
                  {{ $v.filtro.renavam.$params.minLength.min }} dígitos.
                </div>
                <b-field
                  v-show="tipoPesquisa === 'placaait'"
                  label="Auto de Infração"
                  message="* Campo obrigatório"
                  class="mb-3"
                >
                  <b-input
                    v-model="filtro.ait"
                    name="auto de infração"
                    maxlength="10"
                    @change="$v.filtro.ait.$touch()"
                  />
                </b-field>
                <div class="error mb-1" v-if="!$v.filtro.ait.minLength">
                  O campo deve ter no mínimo
                  {{ $v.filtro.ait.$params.minLength.min }} dígitos.
                </div>
                <b-field
                  v-show="tipoPesquisa === 'placaprotocolo'"
                  label="Número do Protocolo de Indicação"
                  class="mb-0"
                >
                  <b-input
                    v-model="filtro.protocolo"
                    name="numero do protocolo"
                    @change="$v.filtro.protocolo.$touch()"
                  />
                </b-field>
                  <div>
                    <vue-recaptcha
                      ref="recaptcha"
                      sitekey="6Lc0OUkUAAAAABxFDBmKvb8XC9L7Lhe5qxhImQ6W"
                      size="normal"
                      @verify="recaptchaVerify"
                      @expired="verified = false"
                    />
                    <span v-show="recaptchaErro" class="has-text-danger is-size-7">Você é um robô?</span>
                  </div>
                <div style="padding-top:20px;">
                  <b-button type="submit" class="is-primary" @click="click">
                    Pesquisar
                  </b-button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="herodiv-body">
      <div class="container">
        <div>
          <div class="columns is-vcentered">
            <b-icon icon="folder" size="is-large" />
            <div class="is-size-4 has-margin-left">
              Editais
            </div>
          </div>
          <div v-if="edital === 'gru'">
            <p>Aqui se encontram os editais de publicações das notificações de Autuação, Penalidade e Penalidade por Não Indicação de Condutor referentes a infrações de trânsito emitidas pela STT.</p>
            <p>Você pode pesquisar pelo mês e o dia da postagem dessas notificações.</p>
            <p>São listados cada Auto de Infração lavrado, com suas respectivas placas, instruções para entrada de recursos/indicação e prazos.</p>
          </div>
          <div v-else-if="edital === 'pira'">
            <p>Aqui se encontram os editais de publicações das notificações de Autuação, Penalidade e Penalidade por Não Indicação de Condutor referentes a infrações de trânsito emitidas pela Semuttran.</p>
            <p>Você pode pesquisar pelo mês e o dia da postagem dessas notificações.</p>
            <p>São listados cada Auto de Infração lavrado, com suas respectivas placas, instruções para entrada de recursos/indicação e prazos.</p>
          </div>
        </div>
        <div class="has-margin-top">
          <div class="columns is-vcentered">
            <b-icon icon="magnify" size="is-large" />
            <div class="is-size-4 has-margin-left">
              Consulta Infração / Cadastro de Indicação
            </div>
          </div>
          <div>
            <p>Consulte infrações de um veículo, informando a Placa e o Renavam.</p>
            <p>Serão listadas as infrações em aberto, podendo emitir segundas vias, verificar pagamentos efetuados, consultar as imagens que deram origem à infração (se disponíveis), e também é possivel o cadastro de uma Indicação de Condutor para a infração.</p>
          </div>
        </div>
        <div class="has-margin-top">
          <div class="columns is-vcentered">
            <b-icon icon="file-document" size="is-large" />
            <div class="is-size-4 has-margin-left">
              Formulários
            </div>
          </div>
          <div>
            <p>Obtenha aqui os formulários para dar entrada nos processos de: Indicação de Condutor, Defesa de Autuação, Advertência, Jari, Cetran e Pedido de Restituição.</P>
            <p>Os formulários possuem informações sobre os documentos necessários e endereço para envio.</P>
            <p>É possível preencher os formulários pelo computador ou à mão. Basta clicar nos campos e preencher as informações corretas em cada campo, e quando terminar aperte CTRL+P para imprimir.</P>
          </div>
        </div>
        <div class="has-margin-top navEquip">
          <div class="columns is-vcentered">
            <b-icon icon="camera" size="is-large" />
            <div class="is-size-4 has-margin-left">
              Fiscalização Eletrônica
            </div>
          </div>
          <p>Atendendo a resolução do CONTRAN N.º 804/20, tenha acesso aos locais onde ocorre fiscalização eletrônica.
          Essa informação possibilita que os condutores estejam cientes dos pontos de monitoramento e ajam de maneira adequada.
          A transparência na divulgação desses dados é crucial para conscientizar os motoristas e reduzir as infrações.
          Portanto, é essencial que todos os condutores estejam informados sobre essa lista e sigam as normas de trânsito em todos os locais indicados.</P>
        </div>
        <div class="has-margin-top has-margin-bottom">
          <div class="columns is-vcentered">
            <div class="has-margin-left">
              <img src="../assets/LGPD.png" style="max-width: 80px;"/>
            </div>
          </div>
          <div>
            <div class="content is-small">
              <h4>Política de Privacidade</h4>
              <p>LGPD é a sigla adotada para designar a Lei Geral de Proteção de Dados Pessoais (Lei nº 13.709) que entrou em vigor em 18 de setembro de 2020. A LGPD se destina a proteger direitos fundamentais de liberdade, privacidade e o livre desenvolvimento da pessoa física, por meio da proteção de seus dados pessoais.</p>
              <p>A Lei impõe padrões de segurança por meio da regulação das operações de tratamento de dados pessoais, realizadas por pessoa física e pessoas jurídicas de direito público ou privado, em meio físico ou digital, definindo responsabilidades e sanções para aqueles que violam esses padrões.</p>
              <p>A Prefeitura dessa Município dispõe de tecnologia apta a garantir a segurança dos dados pessoais recebidos a fim de evitar violações e acessos não autorizados, e de situações acidentais ou ilícitas de destruição, perda, alteração, comunicação ou difusão, nos termos do artigo 46 da LGPD.</p>
              <p>Garante-se a aqueles que disponibilizarem os dados pessoais a Prefeitura todos os direitos previstos no artigo 18 da LGPD, dentre os quais: confirmação da existência de tratamento; acesso aos dados; correção de dados incompletos, inexatos e desatualizados; anonimização, bloqueio ou eliminação de dados desnecessários; portabilidade de dados; eliminação de dados tratados com o consentimento do titular; informação das entidades públicas e privadas com as quais o controlador realizou uso compartilhado de dados; revogação do consentimento; informação sobre a possibilidade de não fornecer consentimento e sobre as consequências desta negativa, dentre outros.</p>
              <p>Ao acessar nosso site são baixados automaticamente cookies em seu dispositivo. As informações coletadas por meio destes cookies são utilizadas para melhorar e personalizar a experiência do usuário, sendo que alguns cookies podem, por exemplo, ser utilizados para lembrar as preferências e escolhas do usuário, bem como para o oferecimento de conteúdo personalizado. O usuário poderá se opor à utilização de cookies pelo site, bastando que os desative no momento em que comece a utilizar o serviço. A desativação de todos os cookies, no entanto, não será possível, uma vez que alguns deles são essenciais para que o site funcione corretamente.</p>
              <p>Caso o usuário queira acessar seus dados ou queira maiores esclarecimentos acerca do tratamento ou tenha dúvidas sobre a política de privacidade da Prefeitura, poderá entrar em contato.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required, minLength, numeric } from 'vuelidate/lib/validators'
import VueRecaptcha from 'vue-recaptcha'
import { mapActions } from 'vuex'
export default {
  name: 'HomePage',
  components: {
    VueRecaptcha
  },
  layout: process.env.VUE_APP_LAYOUT_DEFAULT,
  data () {
    return {
      valid: false,
      filtro: {
        placa: '',
        renavam: '',
        ait: '',
        protocolo: ''
      },
      tipoPesquisa: 'placarenavam',
      verified: false,
      loading: false,
      recaptchaErro: false,
      edital: process.env.VUE_APP_LAYOUT_DEFAULT
    }
  },
  validations: {
    filtro: {
      placa: { required, minLength: minLength(7) },
      renavam: { numeric, minLength: minLength(11) },
      ait: { minLength: minLength(9) }
    }
  },
  methods: {
    ...mapActions('sessions', ['newSession']),
    click () {
      if (this.verified && !this.$v.$invalid) { // Validação bot e inputs
        this.loading = true
        this.newSession(mountData(this.filtro, this.tipoPesquisa))
          .then(() => {
            this.$router.push('/infracoes')
          })
          .catch(() => {
            this.$buefy.dialog.alert({
              message:
                'Não foi localizada infração com esses dados, tente novamente mais tarde.',
              confirmText: 'Retornar'
            })
            this.loading = false
          })
      } else {
        this.recaptchaErro = true
        this.$v.$touch()
      }
    },
    recaptchaVerify (ret) {
      if (ret) {
        this.verified = true
        this.recaptchaErro = false
      }
    },
    clearRadios () {
      this.filtro.renavam = ''
      this.filtro.ait = ''
      this.filtro.protocolo = ''
    }
  },
  head () {
    return {
      script: [
        {
          src:
            'https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit'
        }
      ]
    }
  }
}

function mountData (params, tipoPesquisa) {
  let formatedAit = ''
  if (tipoPesquisa === 'placaait') {
    const ait = params.ait.replace(/ /g, '')
    formatedAit = [
      ait.slice(0, 1),
      ' ',
      ait.slice(1, 3),
      ' ',
      ait.slice(3)
    ].join('')
  }
  const ret = {
    placa: params.placa,
    tipoConsulta: tipoPesquisa
  }
  switch (tipoPesquisa) {
    case 'placarenavam':
      ret.param = params.renavam
      break
    case 'placaait':
      ret.param = formatedAit
      break
    case 'placaprotocolo':
      ret.param = params.protocolo
      break
  }
  return ret
}
</script>
<style>
.error {
  color: #d11313;
  font-size: 12px;
}
.is-vertical-center {
  display: flex;
  align-items: center;
}
.has-margin-left {
  margin-left: 10px;
}
.has-margin-top {
  margin-top: 30px;
}
.has-margin-bottom {
  margin-bottom: 20px;
}
.b-radio{
    margin: 0px 50px;
}
</style>
