import Vue from 'vue'
import Vuex from 'vuex'
import edital from './editais'
import infracao from './infracao'
import session from './session'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    editais: edital,
    infracoes: infracao,
    sessions: session
  }
})
