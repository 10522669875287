import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const state = () => {
  return {
    hash: '',
    status: 'inactive'
  }
}

export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: true
}
