import defaultAxios from '../../plugin/axios'
import axios from 'axios'

export default {
  getInfracoes ({ commit }, hash) {
    return new Promise((resolve, reject) => {
      defaultAxios.get(`consulta/${process.env.VUE_APP_CLIENTID}/${hash}`).then((ret) => {
        commit('setInfracoes', ret.data)
        resolve(ret)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  async getIndicacoes ({ commit }, infracaoId) {
    await axios.get(`${process.env.VUE_APP_INDICACAO}/indicacaocidadao/statusporinfracao/${infracaoId}`)
      .then((ret) => {
        commit('adicionarStatusIndicacao', {
          infracaoId,
          indicacoes: ret
        })
      })
  },
  async getTiposRecursoCliente ({ commit }) {
    await axios.get(`recurso/tipos-recurso/${process.env.VUE_APP_CLIENTID}`)
      .then((ret) => {
        commit('setTiposRecurso', ret)
      })
  }
}
