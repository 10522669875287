const infracoesPendentes = state => state.infracoes.find(i => i.isPending === true)
const infracoesFechadas = state => state.infracoes.find(i => i.isPending === false)
const infracoes = state => state.infracoes
const infracao = state => idInfracao => state.infracoes.find(i => i.idInfracao === idInfracao)
const placa = state => state.placa

const indicacoes = state => (idInfracao) => {
  const infracao = state.infracoes.find(i => i.idInfracao === idInfracao)
  let ret = []
  if (infracao != null) {
    ret = infracao.indicacoes.filter(function (i) {
      return i.status.toUpperCase() !== 'RECUSADA'
    })
  }
  return ret
}

const indicacoesArquivadas = state => (idInfracao) => {
  const infracao = state.infracoes.find(i => i.idInfracao === idInfracao)
  let ret = []
  if (infracao != null) {
    ret = infracao.indicacoes.filter(function (i) {
      return i.status.toUpperCase() === 'RECUSADA'
    })
  }
  return ret
}

const podeIndicarCondutor = state => (idInfracao) => {
  const infracao = state.infracoes.find(i => i.idInfracao === idInfracao)
  if (infracao != null) {
    if (infracao.responsavelEnquadramento !== 'P') {
      return infracao.status.toUpperCase() !== 'FECHADA'
    }
  }
  return false
}
const getTiposRecurso = state => () => {
  return state.tiposRecurso
}

export default {
  infracoesPendentes,
  infracoesFechadas,
  infracoes,
  infracao,
  placa,
  indicacoes,
  indicacoesArquivadas,
  podeIndicarCondutor,
  getTiposRecurso
}
