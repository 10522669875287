import axios from '../../plugin/axios'

export default {
  newSession ({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios.post(`sessao/${process.env.VUE_APP_CLIENTID}/iniciarsessao`, data).then((ret) => {
        commit('SESSION_SUCCESS', ret.data.session)
        if (process.browser) {
          localStorage.setItem('placa-renavam-hash', ret.data.session, {
            patch: '/',
            maxAge: 60 * 30
          })
          resolve()
        }
      }).catch((err) => {
        reject(err)
      })
    })
  }
}
