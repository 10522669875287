import actions from './actions'
import mutations from './mutations'
import getters from './getters'

const state = () => {
  return {
    infracoes: [],
    infracao: {},
    placa: '',
    valorTotalMultas: 0,
    indicacao: {
      condutor: {
        nomeCompleto: '',
        cnh: '',
        ufCNH: null,
        tipoCNH: 'cnh',
        semAssinatura: false
      },
      imagens: {
        docProp: null,
        docCNH: null,
        formIndc: null,
        docResp: null
      }
    }
  }
}

export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: true
}
