
const SESSION_REQUEST = (state) => {
  state.status = 'loading'
}

const SESSION_SUCCESS = (state, hash) => {
  state.hash = hash
  state.state = 'active'
}

const CLEAR_SESSION = (state) => {
  state.hash = ''
  state.status = 'inactive'
}

export default {
  SESSION_REQUEST,
  SESSION_SUCCESS,
  CLEAR_SESSION
}
