import axios from '../../plugin/axios'

export default {
  getNotificacaoAutuacao ({
    commit
  }, dtPostagem) {
    return axios.get(`consulta/${process.env.VUE_APP_CLIENTID}/datasedital/NotificacaoDeAutuacao/${dtPostagem}`).then((ret) => {
      commit('setDatasNotificacaoAutuacao', ret)
    })
  },
  getNotificacaoPenalidade ({
    commit
  }, dtPostagem) {
    return axios.get(`consulta/${process.env.VUE_APP_CLIENTID}/datasedital/NotificacaoDePenalidade/${dtPostagem}`).then((ret) => {
      commit('setDatasNotificacaoPenalidade', ret)
    })
  },
  getNIC ({
    commit
  }, dtPostagem) {
    return axios.get(`consulta/${process.env.VUE_APP_CLIENTID}/datasedital/Nic/${dtPostagem}`).then((ret) => {
      commit('setDatasNic', ret)
    })
  }
}
