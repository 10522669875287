<template>
  <div id="app">
    <nav-pira v-if="layout === 'pira'"></nav-pira>
    <nav-gru v-if="layout === 'gru'"></nav-gru>
    <nav-mogi v-if="layout === 'mogi'"></nav-mogi>
    <nav-serget v-if="layout === 'serget'"></nav-serget>
  </div>
</template>

<script>
export default {
  components: {
    NavPira: () => import('./layouts/pira.vue'),
    NavGru: () => import('./layouts/gru.vue'),
    NavMogi: () => import('./layouts/mogi.vue'),
    NavSerget: () => import('./layouts/serget.vue')
  },
  computed: {
    layout () {
      return process.env.VUE_APP_LAYOUT_DEFAULT
    }
  }
}
</script>
