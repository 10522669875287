import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../pages/index.vue'

const DEFAULT_TITLE_6875 = 'Consulta de Infração – Semuttran – http://semuttran.piracicaba.sp.gov.br'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: index
  },
  {
    path: '/edital',
    name: 'Edital',
    component: () => import('../pages/edital.vue')
  },
  {
    path: '/infracoes',
    name: 'Infracoes',
    component: () => import('../pages/infracoes/index.vue')
  },
  {
    path: '/infracoes/indicacao/:infracao_id',
    name: 'Indicacao',
    component: () => import('../pages/infracoes/indicacao/_infracao_id/index.vue')
  },
  {
    path: '/infracoes/recurso/:infracao_id',
    name: 'Recurso',
    component: () => import('../pages/infracoes/recurso/_infracao_id/index.vue')
  }
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

router.afterEach((to, _from) => {
  Vue.nextTick(() => {
    if (process.env.VUE_APP_CLIENTID === '6875') {
      document.title = to.meta.title || DEFAULT_TITLE_6875
    }
  })
})

export default router
