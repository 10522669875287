export default {
  setDatasNotificacaoAutuacao (state, data) {
    state.notificacaoAutuacao = []
    data.data.datasPostagemLotes.forEach((date) => {
      state.notificacaoAutuacao.push(new Date(date))
    })
  },
  setDatasNotificacaoPenalidade (state, data) {
    state.notificacaoPenalidade = []
    data.data.datasPostagemLotes.forEach((date) => {
      state.notificacaoPenalidade.push(new Date(date))
    })
  },
  setDatasNic (state, data) {
    state.nic = []
    data.data.datasPostagemLotes.forEach((date) => {
      state.nic.push(new Date(date))
    })
  }
}
